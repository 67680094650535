@import url(https://fonts.googleapis.com/css2?family=K2D:wght@400;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600;700;800;900&display=swap);
pre {
  border-radius: 30px;
  background-color: #141342;
  padding: 16px;
  font-size: 14px;
  margin-bottom: 24px;
}

code[class*='language-'],
pre[class*='language-'] {
  color: rgb(191, 199, 213);
  font-family: Inconsolata, Monaco, Consolas, 'Courier New', Courier, monospace;
  direction: ltr;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  line-height: 1.5;
  -moz-tab-size: 4;
    -o-tab-size: 4;
       tab-size: 4;
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
}

/* Code blocks */
pre[class*='language-'] {
  padding: 1em;
  margin: 0.5em 0;
  overflow: auto;
  border-radius: 0.3em;
}

:not(pre) > code[class*='language-'],
pre[class*='language-'] {
  background: #141342;
}

/* Inline code */
:not(pre) > code[class*='language-'] {
  padding: 0.1em;
  border-radius: 0.3em;
}

.token.prolog {
  color: rgb(0, 0, 128);
}

.token.parameter {
  color: rgb(255, 255, 255);
}

.token.comment {
  color: rgb(106, 153, 85);
}

.token.doctype {
  color: rgb(191, 199, 213);
}

.token.cdata {
  color: rgb(191, 199, 213);
}

.token.punctuation {
  color: rgb(136, 198, 190);
}

.token.property {
  color: rgb(252, 146, 158);
}

.token.tag {
  color: rgb(252, 146, 158);
}

.token.class-name {
  color: rgb(250, 200, 99);
}

.token.boolean {
}

.token.constant {
  color: rgb(100, 102, 149);
}

.token.symbol {
  color: rgb(141, 200, 145);
}

.token.deleted {
  color: rgb(141, 200, 145);
}

.token.number {
  color: rgb(181, 206, 168);
}

.token.inserted {
  color: rgb(181, 206, 168);
}

.token.selector {
  color: rgb(215, 186, 125);
}

.token.char {
  color: rgb(209, 105, 105);
}

.token.builtin {
  color: rgb(197, 165, 197);
}

.token.changed {
  color: rgb(197, 165, 197);
}

.token.keyword {
  color: rgb(197, 165, 197);
}

.token.string {
  color: rgb(195, 232, 141);
}

.token.attr-name {
  color: rgb(156, 220, 254);
}

.token.variable {
  color: rgb(156, 220, 254);
}

.token.operator {
  color: #ededed;
}

.token.entity {
  color: #ffffb6;
  cursor: help;
}

.token.url {
  color: #96cbfe;
}

.language-css .token.string,
.style .token.string {
  color: #87c38a;
}

.token.atrule,
.token.attr-value {
  color: #f9ee98;
}

.token.function {
  color: rgb(121, 182, 242);
}

.token.regex {
  color: #e9c062;
}

.token.important {
  color: #fd971f;
}

.token.important,
.token.bold {
  font-weight: bold;
}

.token.italic {
  font-style: italic;
}



.ps .ps__rail-x:hover, .ps .ps__rail-y:hover, .ps .ps__rail-x:focus, .ps .ps__rail-y:focus, .ps .ps__rail-x.ps--clicking, .ps .ps__rail-y.ps--clicking {
    background-color: #000000;
  
}


.ps__rail-y:hover > .ps__thumb-y, .ps__rail-y:focus > .ps__thumb-y, .ps__rail-y.ps--clicking .ps__thumb-y {
    background-color: #9B00F9;
}

.ps__thumb-y {
    background-color: #9B00F9;
}
.carousel-container {
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
}

.carousel-wrapper {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
}

.carousel-content-wrapper {
  width: 100%;
  height: 100%;
}

.carousel-content {
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: all 250ms linear;
  transition: all 250ms linear;
  -ms-overflow-style: none; /* hide scrollbar in IE and Edge */
  scrollbar-width: none; /* hide scrollbar in Firefox */
  height: 100%;
}

/* hide scrollbar in webkit browser */
.carousel-content::-webkit-scrollbar,
.carousel-content::-webkit-scrollbar {
  display: none;
}

.carousel-content > * {
  overflow: hidden;
  width: 100%;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -ms-flex-positive: 1;
      flex-grow: 1;
  /* margin:10px;
  padding-right: 10px */
}

.carousel-content.show-2 > * {
  width: 50%;
}

.carousel-content.show-3 > * {
  width: calc(100% / 3);
  border-radius:30px;
  
}

.carousel-content.show-4 > * {
  width: calc(100% / 4);
}

.left-arrow,
.right-arrow {
  position: absolute;
  z-index: 1;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 48px;
  height: 48px;
  
}

.left-arrow {
  left: 24px;
}

.right-arrow {
  right: 24px;
}

